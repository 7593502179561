@import "default";

.error__message{
  width: 100%;
  padding: 0 40px;
  font-size: $fSize40;
  margin-top: 50px;
}

@media screen and (max-width: 600px){
  .error__message{
    padding: 0 10px;
    font-size: $fSize40s;
  }
}
