@import "default";

.main__header{
  width: 100%;
  text-align: center;
  margin-top: 50px;
  font-size: $fSize40;
  padding: 0 40px;
}

.contact__block{
  margin-top: 50px;
  display: flex;
  font-size: $fSize28;
  padding: 0 40px;
  justify-content: space-between;
}
.contact__block__3{
  width: 48%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.contact__block__3__el{
  padding-bottom: 20px;
}

@media screen and (max-width: 800px) {
  .contact__block{
    padding: 0 10px;
    font-size: $fSize36s;
  }
}


@media screen and (max-width: 600px) {
  .contact__block{
    flex-direction: column;
  }
  .contact__block__3{
    width: 100%;
  }
  .contact__block{
    padding: 0 10px;
    font-size: $fSize28s;
  }
  .main__header{
    font-size: $fSize40s;
    padding: 0 10px;
  }
}