@import "default";
:focus, :active{outline: none}
a:focus, a:active{outline: none}
html{border: 0;cursor: default;font-size: 10px; width: 100%;height: 100%; color: black}
body{border: 0;cursor: default;width: 100%;height: 100%;padding: 0;margin: 0; font-family: 'Comfortaa', cursive;
}
input, button, textarea{font-family: inherit}
*, *::before, *::after {box-sizing: border-box;}
a, a:visited, a:hover{text-decoration: none; cursor: pointer}
h1{font-size: inherit; font-weight: inherit;}

.wrapper {
  min-height: 100vh;
  display: flex;
  flex-direction: column;
}

.main{
  flex: 1 1 auto;
}

