@import "default";

.slider__container{
  width: 100%;
  display: flex;
  align-items: center;
  height: 600px;
  justify-content: space-between;
  padding: 0 40px;
}

.slider__photo1, .slider__photo3{
  object-fit: cover;
  z-index: -1;
  position: absolute;
  width: 350px;
  opacity: 0.7;
  border-radius: 30px;
}

.slider__photo1{
  height: 300px;
  left: 20%;
}

.slider__photo2{
  height: 400px;
  width: 350px;
  object-fit: cover;
  border-radius: 30px;
  border: 6px solid #B68D6E;
}

.slider__photo3{
  height: 300px;
  right: 20%;
}

.slider__arrowRight, .slider__arrowLeft{
  cursor: pointer;
}

@media screen and (max-width: 800px) {
  .slider__photo1{
    height: 250px;
    left: 15%;
    width: 270px;
  }

  .slider__photo2{
    height: 300px;
    width: 270px;
    border: 4px solid #B68D6E;
  }

  .slider__photo3{
    height: 250px;
    right: 15%;
    width: 270px;
  }
}

@media screen and (max-width: 600px) {
  .slider__arrowRight, .slider__arrowLeft{
    width: 30px;
  }
  .slider__photo1{
    height: 200px;
    left: 12%;
    width: 200px;
  }

  .slider__photo2{
    height: 250px;
    width: 200px;
    border: 4px solid #B68D6E;
  }

  .slider__photo3{
    height: 200px;
    right: 12%;
    width: 200px;
  }
  .slider__container{
    padding: 0 10px;
  }
}