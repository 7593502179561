@import "default";


.footer{
  height: 180px;
  width: 100%;
  padding: 0 30px;
  display: flex;
  background: #B68D6E;
  justify-content: space-between;
}

.footer__links{
  display: flex;
  padding-top: 45px;
  margin-right: 30px;
  width: 300px;
  justify-content: space-between;
 }

.footer__links__column{
  display: flex;
  flex-direction: column;
  margin: 0 5px;
}


.footer__linkToPage{
  color: black;
  font-size: $fSize14;
  transition: .15s linear all;
}

.footer__linkToScroll{
  font-size: $fSize14;
  color: #4B4B4B;
  transition: .15s linear all;
}

.footer__linkToPage:hover, .footer__linkToScroll:hover{
  color: white;
}

.footer__contacts{
  width: 300px;
  display: flex;
  flex-direction: column;
  height: 100%;
  justify-content: space-evenly;
  align-items: flex-end;
  margin-left: 30px;
}

.footer__contact__us{
  color: black;
  font-size: $fSize20;
  width: fit-content;
  display: inline-block;
}



.footer__logo{
  width: 200px;
  height: 100%;
  display: flex;
  align-items: center;
}
.footer__logo__size{
  width: 200px;
  transition: .15s linear all;
}

.footer__logo__size:hover{
  transform: scale(1.05);
}


@media screen and (max-width: 800px){
  .footer{
    padding: 0 10px;
  }
  .footer__contact__us{
    font-size: $fSize14
  }
  .footer__links{
    margin-right: 10px;
  }
  .footer__contacts{
    margin-left: 10px;
  }

  .footer__logo__size, .footer__logo{
    width: 100px;
  }

  .footer__contacts, .footer__links{
    width: 240px;
  }
}

@media screen and (max-width: 600px){
  .footer__links{
    width: 100%;
    margin: 0;
    padding: 30px 0;
  }
  .footer{
    padding: 0 10px 30px;
    flex-wrap: wrap;
    height: auto;
    align-items: center;
  }
  .footer__contacts{
    width: auto;
    margin: 0;
    height: 50px;
  }
}