@import "default";

.mp__firstBlock{
  width: 100%;
  display: flex;
  justify-content: center;
  margin-top: 50px;
}

.mp__firstBlock__text{
  width: 60%;
  font-size: $fSize36;
  text-transform: uppercase;
  text-align: center;
  line-height: 1.25em;
  font-family: "Kreadon Medium", sans-serif;
}

.mp__firstBlock__text__name {
  color: #B68D6E;
  font-size: $fSize40;
}

.mp__threeblocks{
  height: 60vh;
  width: 100%;
  padding: 0 40px;
  margin-top: 100px;
  display: flex;
  justify-content: space-between;
  flex-direction: row-reverse;
}

.mp__threeblocks__two{
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  width: 36%;
}
.mp__threeblocks__one{
  width: 60%;
}

.mp__threeblocks__two__el__1{
  background: url("../photos/main_1.jpg");
}

.mp__threeblocks__two__el__2{
  background: url("../photos/main_2.jpg");
}

.mp__threeblocks__one__el{
  background: url("../photos/main_3.jpg");
}

.mp__threeblocks__two__el__1, .mp__threeblocks__two__el__2, .mp__threeblocks__one__el{
  border-radius: 30px;
  background-size: cover;
  width: 100%;
  color: white;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: $fSize36;
  text-shadow: black 1px 0 10px;
}

.mp_thhh1{
  height: 100%;
  display: block;
}
.mp_thhh{
  height: 48%;
}

.mp__threeblocks__two__el__1, .mp__threeblocks__two__el__2, .mp__threeblocks__one__el{
  height: 100%;
}


.mp__whoweare{
  margin-top: 50px;
  width: 100vw;
  position: relative;
  height: 600px;
}

.mp__whoweare__video__cont{
  filter: brightness(60%);
  position: absolute;
  top: 0;
  left: 0;
  z-index: -1;
}

.mp__whoweare__video{
  height: 600px;
  width: 100vw;
  object-fit: cover;
}

.mp__whoweare__header{
  width: 100%;
  text-align: center;
  font-size: $fSize36;
  color: white;
  padding-top: 40px;
  text-shadow: black 1px 0 10px;
  font-family: "Kreadon Medium", sans-serif;
}

.mp__whoweare__content{
  width: 60%;
  color: white;
  padding: 0 40px;
  font-size: $fSize20;
  margin-top: 60px;
  text-shadow: black 1px 0 10px;
}

.mp__whoweare__buy{
  padding: 0 40px;
  margin-top: 60px;
}

.mp__whoweare__buy__text{
  display: flex;
  align-items: center;
  font-size: $fSize20;
  color: #B68D6E;
  transition: .15s linear all;
  text-shadow: black 1px 0 10px;
}

.mp__whoweare__buy__text:hover{
  color: white
}

.mp__whywe{
  margin-top: 50px;
  font-size: $fSize36;
  text-align: center;
  font-family: "Kreadon Medium", sans-serif;
}

.mp__whywe__content, .mp__loyl{
  margin-top: 50px;
  width: 100%;
  display: flex;
  justify-content: space-between;
  padding: 0 40px;
}
.mp__whywe__content__text_block{
  width: 48%;
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
}
.mp__whywe__content__text{
  font-size: $fSize20;
}
.mp__whywe__content__info{
  width: 48%;
}
.mp__whywe__content__info__text{
  width: 100%;
  color: #B68D6E;
  border-radius: 30px;
  border: 1px solid #B68D6E;
  font-size: $fSize20;
  padding: 20px;
}

.mp__loyl__photo{
  display: flex;
  justify-content: center;
  width: 48%;
}

.mp__loyl_size{
  width: 100%;
}









@media screen and (max-width: 600px){
  .mp__whywe__content__text, .mp__whywe__content__info__text, .mp__whoweare__buy__text{
    font-size: $fSize20s;
  }
  .mp__whywe, .mp__whoweare__header{
    font-size: $fSize36s;
  }

  .mp__whywe__content__text_block, .mp__whywe__content__info, .mp__loyl__photo{
    width: 100%;
  }
  .mp__whywe__content__info, .mp__loyl__photo{
    margin-top: 20px;
  }
  .mp__whywe__content, .mp__loyl{
    flex-direction: column;
  }
  .mp__threeblocks__two__el__1, .mp__threeblocks__two__el__2, .mp__threeblocks__one__el{
    border-radius: 10px;
    font-size: $fSize36s
  }
  .mp__threeblocks, .mp__whywe__content, .mp__loyl{
    padding: 0 10px;
  }
  .mp__threeblocks{
    margin-top: 50px;
  }
  .mp__firstBlock__text{
    width: 90%;
    font-size: $fSize36s;
  }

  .mp__firstBlock__text__name {
    font-size: $fSize40s;
  }

  .mp__whoweare__content, .mp__whoweare__content{
    margin-top: 20px;
    font-size: $fSize20s;
    width: 100%;
  }
  .mp__whoweare__buy{
    margin-top: 20px;
  }
}