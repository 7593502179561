@import "default";

.main__header{
  width: 100%;
  text-align: center;
  margin-top: 50px;
  font-size: $fSize40;
  padding: 0 40px;
  font-family: "Kreadon Medium", sans-serif;
}

.main__blockTextPhoto{
  margin-top: 50px;
  display: flex;
  justify-content: space-between;
  width: 100%;
  padding: 0 40px;
  align-items: center;
}
.main__block__text{
  font-size: $fSize20;
  width: 48%;
}
.main__block__photo{
  width: 48%;
  border-radius: 30px;
  object-fit: cover;
}

.reversedmain__block{
  display: flex;
  flex-direction: row-reverse;
}



@media screen and (max-width: 600px) {
  .main__header{
    font-size: $fSize40s;
    padding: 0 10px;
  }
  .main__blockTextPhoto{
    flex-direction: column;
    padding: 0 10px;
  }
  .main__block__text, .main__block__photo{
    width: 100%;
  }
  .main__block__photo{
    margin-top: 50px;
    border-radius: 10px;
  }
  .main__block__text{
    font-size: $fSize20s;
  }
}