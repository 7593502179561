@import "default";

.nav{
  min-height: 100px;
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0 30px;
  position: relative;
}

.nav__call_el{
  border-radius: 4px;
  color: white;
  font-size: $fSize14;
  background: #B68D6E;
  padding: 5px;
  border: 1px solid white;
  transition: .15s linear all;
}
.nav__call_el:hover{
  border: 1px solid #B68D6E;
  background: white;
  color: #B68D6E;
}

.nav__logo{
  transition: .15s linear all;
}
.nav__logo:hover{
  transform: scale(1.1)
}

.nav__logo{
}
.nav__call{
  display: flex;
  justify-content: flex-end;
}
.nav__content{
  padding: 0 10%;
  flex: 1 1 auto;
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
}

.underLine{
  border-bottom: 1px solid #B68D6E;
}

.linkToPage{
  color: black;
  font-size: $fSize14;
  transition: .15s linear all;
  margin: 5px 10px;
  width: fit-content;
  display: inline-block;
}

.linkToPage:hover{
  color: #B68D6E;
}

@media screen and (max-width: 600px){
  .nav__logo{
    position: absolute;
    top: 30px;
    left: 10px;
  }
  .nav__call{
    position: absolute;
    bottom: 30px;
    left: 10px;
  }
  .nav__logo__el{
    width: 98px;
  }
  .nav{
    padding: 0 10px;
  }
  .nav__content{
    padding: 0;
    flex-direction: column;
    align-content: flex-end;
  }
}